import { render, staticRenderFns } from "./surveyPage.vue?vue&type=template&id=6f8ae090&scoped=true&"
import script from "./surveyPage.vue?vue&type=script&lang=js&"
export * from "./surveyPage.vue?vue&type=script&lang=js&"
import style0 from "./surveyPage.vue?vue&type=style&index=0&id=6f8ae090&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f8ae090",
  null
  
)

export default component.exports